<template>
  <v-row justify="center">
    <v-dialog
      v-model="shower"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card
        class="elevation-0"
        :color="$vuetify.theme.dark ? '#333333' : '#f5faff'"
      >
        <v-toolbar dark :color="color.theme" v-if="resolutionScreen >= 500">
          <v-toolbar-title>{{ $t("admintoolbar.register") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="$emit('closedialog'), $emit('openDrag')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-toolbar v-else>
          <v-icon :color="$vuetify.theme.dark ? 'white' : color.theme"
            >mdi-account-plus</v-icon
          >
          <v-toolbar-title
            class="pl-2"
            :style="
              $vuetify.theme.dark ? 'color: white' : 'color:' + color.theme
            "
            >{{ $t("admintoolbar.register") }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              :color="$vuetify.theme.dark ? 'white' : color.theme"
              icon
              @click="$emit('closedialog'), $emit('openDrag')"
            >
              <v-icon class="pt-1">mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="pa-4" style="height: 100vh">
          <v-layout row wrap justify-center>
            <v-flex lg12 xs12 class="mr-4 ml-4">
              <v-card class="elevation-1">
                <v-layout
                  justify-center
                  v-if="resolutionScreen >= 500 && e1 != 3"
                >
                  <v-flex
                    lg12
                    class="ma-2 ml-4 mr-4"
                    v-if="loaddataprogress === true"
                  >
                    <v-overlay absolute color="white" dark>
                      <v-progress-circular
                        :size="50"
                        color="#263238"
                        indeterminate
                      ></v-progress-circular>
                    </v-overlay>
                  </v-flex>
                  <v-form v-model="valid" class="ma-1">
                    <v-layout row wrap justify-center>
                      <v-flex xs12 sm12 md6 lg6 class="pa-1">
                        <v-container fluid>
                          <v-layout>
                            <v-flex xs12 sm12 md12 lg12>
                              <p
                                style="font-size: 20px"
                                class="font-weight-black"
                              >
                                ลงทะเบียนบัญชีนิติบุคคล เต็มรูปแบบ (Register
                                Account Bussiness)
                              </p>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md12 lg12 class="mt-1"
                              >ข้อมูลทั่วไป (General Information)</v-flex
                            >
                          </v-layout>
                          <v-layout>
                            <v-flex xs6 sm6 md6 lg6>
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-2 pb-0 font-weight-medium"
                                v-model="tax_num"
                                label="เลขประจำตัวผู้เสียภาษี 13 หลัก* / Identification Number"
                                maxlength="13"
                                :rules="requiredtax_num"
                                :error-messages="tax_numError"
                                required
                                @input="fn_search_taxid(), $v.tax_num.$touch()"
                                @blur="$v.tax_num.$touch()"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md12 lg6 class="mt-1">
                              <v-select
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="typebusiness"
                                :items="listtypebusiness"
                                label="ประเภทนิติบุคคล* / Type Business (TH)"
                                :rules="[rules.noSpecialChar]"
                                :error-messages="typebusinessError"
                                required
                                @input="
                                  fn_checktypebizth(), $v.typebusiness.$touch()
                                "
                                @blur="$v.typebusiness.$touch()"
                              ></v-select>
                            </v-flex>
                            <v-flex xs12 sm12 md12 lg6 class="mt-1">
                              <v-text-field
                                clearable
                                @click:clear="clearbtnnameth()"
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="name_business_th"
                                label="ชื่อผู้ประกอบการ(ไทย)* / Business Name (TH)"
                                :rules="requiredname"
                                :error-messages="name_business_thError"
                                required
                                @input="
                                  fn_name_th(), $v.name_business_th.$touch()
                                "
                                @blur="$v.name_business_th.$touch()"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md12 lg6 class="mt-1">
                              <v-select
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="typebusiness_eng"
                                :items="listtypebusiness_eng"
                                label="ประเภทนิติบุคคล(อังกฤษ)* / Type Business (Eng)"
                                :rules="[rules.noSpecialChar]"
                                :error-messages="typebusiness_engError"
                                required
                                @input="
                                  fn_checktypebizeng(),
                                    $v.typebusiness_eng.$touch()
                                "
                                @blur="$v.typebusiness_eng.$touch()"
                              ></v-select>
                            </v-flex>
                            <v-flex xs12 sm12 md12 lg6 class="mt-1">
                              <v-text-field
                                clearable
                                @click:clear="clearbtnnameeng()"
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="name_business_eng"
                                label="ชื่อผู้ประกอบการ(อังกฤษ)* / Business Name (Eng)"
                                :rules="requirednameeng"
                                :error-messages="name_business_engError"
                                required
                                @input="
                                  fn_name_eng(), $v.name_business_eng.$touch()
                                "
                                @blur="$v.name_business_eng.$touch()"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md12 lg12 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="name_invoice_th"
                                label="ชื่อที่ใช้พิมพ์ในใบกำกับภาษี(ไทย) / Tax Invoice Name (TH)"
                                @click="fn_name_th"
                                :rules="requiredname_th"
                                :error-messages="name_invoice_thError"
                                required
                                @input="$v.name_invoice_th.$touch()"
                                @blur="$v.name_invoice_th.$touch()"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md12 lg12 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="name_invoice_eng"
                                label="ชื่อที่ใช้พิมพ์ในใบกำกับภาษี(อังกฤษ) / Tax Invoice Name (Eng)"
                                @click="fn_name_eng"
                                :rules="requirednameeng"
                                :error-messages="name_invoice_engError"
                                required
                                @input="$v.name_invoice_eng.$touch()"
                                @blur="$v.name_invoice_eng.$touch()"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md6 lg6 class="mt-1">
                              <v-layout>
                                <v-flex xs2 sm2 md2 lg2
                                  >สาขา*<br />(Branch)</v-flex
                                >
                                <v-flex xs10 sm10 md10 lg10>
                                  <v-radio-group
                                    v-model="radios"
                                    mandatory
                                    @change="fn_radiobranch"
                                  >
                                    <v-radio
                                      label="สำนักงานใหญ่ (Head office)"
                                      value="สำนักงานใหญ่"
                                    ></v-radio>
                                    <v-radio
                                      label="สาขาอื่นๆ (Other branches)"
                                      value="สาขาอื่นๆ"
                                    ></v-radio>
                                    <!-- <p style="color:red; font-size:12px;" >*ถ้าต้องการสมัครสาขาอื่นๆ กรุณาติดต่อสาขาใหญ่</p> -->
                                  </v-radio-group>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 sm12 md6 lg6 class="mt-1">
                              <v-layout>
                                <v-flex xs6 sm6 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0 font-weight-medium"
                                    v-model="branch_no"
                                    :disabled="radios === 'สำนักงานใหญ่'"
                                    label="รหัสสาขา / Branch No"
                                    maxlength="5"
                                    :rules="requiredbranch_no"
                                    :error-messages="branch_noError"
                                    required
                                    @input="$v.branch_no.$touch()"
                                    @blur="$v.branch_no.$touch()"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0 font-weight-medium"
                                    v-model="branch_name"
                                    :disabled="radios === 'สำนักงานใหญ่'"
                                    label="ชื่อสาขา/Branch Name"
                                    :rules="[rules.noSpecialChar]"
                                    :error-messages="branch_nameError"
                                    required
                                    @input="$v.branch_name.$touch()"
                                    @blur="$v.branch_name.$touch()"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md6 lg6 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="email_business"
                                label="อีเมล* / E-mail"
                                :rules="requiredemail_biz"
                                :error-messages="email_businessError"
                                required
                                @input="$v.email_business.$touch()"
                                @blur="$v.email_business.$touch()"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm12 md6 lg6 class="mt-1">
                              <v-layout>
                                <v-flex xs6 sm6 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0 font-weight-medium"
                                    v-model="phone_number_biz"
                                    label="เบอร์มือถือ* / Mobile No"
                                    maxlength="10"
                                    :rules="requiredTextphone"
                                    :error-messages="phone_number_bizError"
                                    required
                                    @input="$v.phone_number_biz.$touch()"
                                    @blur="$v.phone_number_biz.$touch()"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0 font-weight-medium"
                                    v-model="tel_biz"
                                    maxlength="10"
                                    label="เบอร์โทรศัพท์* / Telephone"
                                    :rules="requiredTexttelephone"
                                    :error-messages="tel_bizError"
                                    required
                                    @input="$v.tel_biz.$touch()"
                                    @blur="$v.tel_biz.$touch()"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                          <!-- <v-layout>
                                <v-flex xs12 sm12 md12 lg12 class="mt-4">
                                <v-btn large outlined @click="fn_logout()"
                                    >ออกจากระบบ<br />(Log out)</v-btn
                                >
                                </v-flex>
                            </v-layout> -->
                        </v-container>
                      </v-flex>

                      <v-flex xs12 sm12 md6 lg6 class="pa-1 text-center">
                        <v-container fluid>
                          <v-layout>
                            <v-flex
                              xs12
                              sm12
                              md12
                              lg12
                              class="mt-1 text-right"
                              >{{ thai_e_mail }}</v-flex
                            >
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md12 lg12 class="mt-7 text-left"
                              >ข้อมูลที่อยู่ (Address)</v-flex
                            >
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md4 lg4 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-2 pb-0 font-weight-medium"
                                v-model="noaddress"
                                label="เลขที่อยู่* / Number"
                                :rules="requirednoaddress"
                                :error-messages="noaddressError"
                                required
                                @input="$v.noaddress.$touch()"
                                @blur="$v.noaddress.$touch()"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm12 md4 lg4 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-2 pb-0 font-weight-medium"
                                v-model="building"
                                label="อาคาร / Building"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm12 md4 lg4 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-2 pb-0 font-weight-medium"
                                v-model="floor"
                                label="ชั้นที่ / Floor"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md4 lg4 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="noroom"
                                label="เลขที่ห้อง / Room Number"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm12 md4 lg4 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="mooban"
                                label="หมู่บ้าน / Village"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm12 md4 lg4 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="no_moo"
                                label="หมู่ที่ / Moo"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md4 lg4 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="soi"
                                label="ตรอก/ซอย / Soi"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm12 md4 lg4 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="yaek"
                                label="แยก / Yaek"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm12 md4 lg4 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="road"
                                label="ถนน / Road"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md4 lg4 class="mt-1">
                              <v-autocomplete
                                ref="province"
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="province"
                                :items="listprovince"
                                label="จังหวัด* / Province"
                                :rules="[rules.noSpecialChar]"
                                :error-messages="provinceError"
                                required
                                @input="$v.province.$touch()"
                                @blur="$v.province.$touch()"
                              ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm12 md4 lg4 class="mt-1">
                              <v-autocomplete
                                ref="district"
                                outlined
                                dense
                                clearable
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="district"
                                :items="listdistrict"
                                label="อำเภอ/เขต* / District"
                                :rules="[rules.noSpecialChar]"
                                :error-messages="districtError"
                                required
                                @input="$v.district.$touch()"
                                @blur="$v.district.$touch()"
                              ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm12 md4 lg4 class="mt-1">
                              <v-autocomplete
                                ref="subdistrict"
                                outlined
                                dense
                                clearable
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="subdistrict"
                                :items="listsubdistrict"
                                label="ตำบล/แขวง* / Sub-district"
                                :rules="[rules.noSpecialChar]"
                                :error-messages="subdistrictError"
                                required
                                @input="$v.subdistrict.$touch()"
                                @blur="$v.subdistrict.$touch()"
                              ></v-autocomplete>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md4 lg4 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="postcode"
                                label="รหัสไปรษณีย์* / Post Code"
                                maxlength="5"
                                :rules="requiredpostcode"
                                :error-messages="postcodeError"
                                required
                                @input="$v.postcode.$touch()"
                                @blur="$v.postcode.$touch()"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm12 md4 lg4 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="fax"
                                label="โทรสาร / Fax"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs5 sm5 md5 lg5 class="mt-1">
                              หนังสือรับรองบริษัท (PDF)*
                              <v-tooltip v-model="show_icon" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon v-bind="attrs" v-on="on" small>
                                    <v-icon color="red lighten">
                                      mdi-information
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <p class="text-center">
                                  พร้อมลงลายมือชื่อกรรมการของบริษัทพร้อมทั้งตราประทับบริษัท
                                  <br />
                                  (ตามรายละเอียดข้อที่ 3 ในหนังสือรับรอง)
                                </p>
                              </v-tooltip>
                              <br />
                              (Company Certificate)
                              <br />
                              <span style="color:red;font-size: 11px;"
                                >*อัปโหลดไฟล์ได้ไม่เกิน 10 MB</span
                              >
                            </v-flex>
                            <v-flex xs7 sm7 md7 lg7 class="mt-3">
                              <v-file-input
                                v-model="companycertificate"
                                label="กรุณาเลือกไฟล์ / Please select a file"
                                outlined
                                dense
                                type="file"
                                accept="application/pdf"
                                required
                                :rules="[rules.noSpecialChar]"
                                :error-messages="companycertificateError"
                                @input="$v.companycertificate.$touch()"
                                @blur="$v.companycertificate.$touch()"
                              ></v-file-input>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs5 sm5 md5 lg5 class="mt-1">
                              หนังสือรับมอบอำนาจ (PDF)
                              <br />
                              (Power of attorney)
                              <br />
                              <a @click="examplefile()"
                                >แบบฟอร์มหนังสือมอบอำนาจและตัวอย่าง (Power of
                                Attorney Form and Document Example)</a
                              >
                              <br />
                              <span style="color:red;font-size: 11px;"
                                >*อัปโหลดไฟล์ได้ไม่เกิน 10 MB</span
                              >
                            </v-flex>
                            <v-flex xs7 sm7 md7 lg7 class="mt-3">
                              <v-file-input
                                v-model="powerofattorney"
                                label="กรุณาเลือกไฟล์ / Please select a file"
                                outlined
                                dense
                                type="file"
                                accept="application/pdf"
                                @input="$v.powerofattorney.$touch()"
                                @blur="$v.powerofattorney.$touch()"
                              ></v-file-input>
                              <!-- <v-file-input
                                v-model="powerofattorney"
                                label="กรุณาเลือกไฟล์ / Please select a file"
                                outlined
                                dense
                                type="file"
                                accept="application/pdf"
                                required
                                :rules="[rules.noSpecialChar]"
                                :error-messages="powerofattorneyError"                                  
                                @input="$v.powerofattorney.$touch()"
                                @blur="$v.powerofattorney.$touch()"
                            ></v-file-input> -->
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md12 lg12 class="pt-2 text-right">
                              <v-btn
                                large
                                color="primary"
                                @click="fn_uploadapprove_document()"
                                >ลงทะเบียน<br />(Register)</v-btn
                              >
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-layout>
                <v-layout
                  justify-center
                  v-if="resolutionScreen < 500 && e1 != 3"
                >
                  <v-flex
                    lg12
                    class="ma-2 ml-4 mr-4"
                    v-if="loaddataprogress === true"
                  >
                    <v-overlay absolute color="white" dark>
                      <v-progress-circular
                        :size="50"
                        color="#263238"
                        indeterminate
                      ></v-progress-circular>
                    </v-overlay>
                  </v-flex>
                  <v-form v-model="valid" class="ma-1 mr-4 ml-4">
                    <v-layout>
                      <v-flex xs12 sm12 md12 lg12 class="pa-1">
                        <v-container fluid>
                          <v-layout>
                            <v-flex xs12 sm12 md12 lg12>
                              <p
                                style="font-size: 20px"
                                class="font-weight-black text-center"
                              >
                                ลงทะเบียนบัญชีนิติบุคคล เต็มรูปแบบ<br />(Register
                                Account Bussiness)
                              </p>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md12 lg12 class="mt-1 text-center"
                              ><v-icon color="primary" small
                                >account_circle</v-icon
                              >
                              {{ thai_e_mail }}</v-flex
                            >
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md12 lg12 class="mt-1"
                              >ข้อมูลทั่วไป (General Information)</v-flex
                            >
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md12 lg12>
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-2 pb-0 font-weight-medium"
                                v-model="tax_num"
                                label="เลขประจำตัวผู้เสียภาษี 13 หลัก* / Identification Number"
                                maxlength="13"
                                :rules="requiredtax_num"
                                :error-messages="tax_numError"
                                required
                                @input="fn_search_taxid(), $v.tax_num.$touch()"
                                @blur="$v.tax_num.$touch()"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md12 lg12 class="mt-1">
                              <v-select
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="typebusiness"
                                :items="listtypebusiness"
                                label="ประเภทนิติบุคคล* / Type Business (TH)"
                                :rules="[rules.noSpecialChar]"
                                :error-messages="typebusinessError"
                                required
                                @input="
                                  fn_checktypebizth(), $v.typebusiness.$touch()
                                "
                                @blur="$v.typebusiness.$touch()"
                              ></v-select>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md12 lg12 class="mt-1">
                              <v-text-field
                                clearable
                                @click:clear="clearbtnnameth()"
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="name_business_th"
                                label="ชื่อผู้ประกอบการ(ไทย)* / Business Name (TH)"
                                :rules="requiredname"
                                :error-messages="name_business_thError"
                                required
                                @input="
                                  fn_name_th(), $v.name_business_th.$touch()
                                "
                                @blur="$v.name_business_th.$touch()"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md12 lg12 class="mt-1">
                              <v-select
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="typebusiness_eng"
                                :items="listtypebusiness_eng"
                                label="ประเภทนิติบุคคล(อังกฤษ)* / Type Business (Eng)"
                                :rules="[rules.noSpecialChar]"
                                :error-messages="typebusiness_engError"
                                required
                                @input="
                                  fn_checktypebizeng(),
                                    $v.typebusiness_eng.$touch()
                                "
                                @blur="$v.typebusiness_eng.$touch()"
                              ></v-select>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md12 lg12 class="mt-1">
                              <v-text-field
                                clearable
                                @click:clear="clearbtnnameeng()"
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="name_business_eng"
                                label="ชื่อผู้ประกอบการ(อังกฤษ)* / Business Name (Eng)"
                                :rules="requirednameeng"
                                :error-messages="name_business_engError"
                                required
                                @input="
                                  fn_name_eng(), $v.name_business_eng.$touch()
                                "
                                @blur="$v.name_business_eng.$touch()"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md12 lg12 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="name_invoice_th"
                                label="ชื่อที่ใช้พิมพ์ในใบกำกับภาษี(ไทย) / Tax Invoice Name (TH)"
                                @click="fn_name_th"
                                :rules="requiredname_th"
                                :error-messages="name_invoice_thError"
                                required
                                @input="$v.name_invoice_th.$touch()"
                                @blur="$v.name_invoice_th.$touch()"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md12 lg12 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="name_invoice_eng"
                                label="ชื่อที่ใช้พิมพ์ในใบกำกับภาษี(อังกฤษ) / Tax Invoice Name (Eng)"
                                @click="fn_name_eng"
                                :rules="requirednameeng"
                                :error-messages="name_invoice_engError"
                                required
                                @input="$v.name_invoice_eng.$touch()"
                                @blur="$v.name_invoice_eng.$touch()"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md6 lg6 class="mt-1">
                              <v-layout>
                                <v-flex xs12 sm12 md2 lg2
                                  >สาขา* (Branch)</v-flex
                                >
                              </v-layout>
                              <v-layout>
                                <v-flex xs12 sm12 md10 lg10>
                                  <v-radio-group
                                    v-model="radios"
                                    mandatory
                                    @change="fn_radiobranch"
                                  >
                                    <v-radio
                                      label="สำนักงานใหญ่ / Head office"
                                      value="สำนักงานใหญ่"
                                    ></v-radio>
                                    <v-radio
                                      label="สาขาอื่นๆ / Other branches"
                                      value="สาขาอื่นๆ"
                                    ></v-radio>
                                    <!-- <p style="color:red; font-size:12px;" >*ถ้าต้องการสมัครสาขาอื่นๆ กรุณาติดต่อสาขาใหญ่</p> -->
                                  </v-radio-group>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 sm12 md6 lg6 class="mt-1">
                              <v-layout>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0 font-weight-medium"
                                    v-model="branch_no"
                                    :disabled="radios === 'สำนักงานใหญ่'"
                                    label="รหัสสาขา / Branch No"
                                    maxlength="5"
                                    :rules="requiredbranch_no"
                                    :error-messages="branch_noError"
                                    required
                                    @input="$v.branch_no.$touch()"
                                    @blur="$v.branch_no.$touch()"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                              <v-layout>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0 font-weight-medium"
                                    v-model="branch_name"
                                    :disabled="radios === 'สำนักงานใหญ่'"
                                    label="ชื่อสาขา / Branch Name"
                                    :rules="[rules.noSpecialChar]"
                                    :error-messages="branch_nameError"
                                    required
                                    @input="$v.branch_name.$touch()"
                                    @blur="$v.branch_name.$touch()"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md6 lg6 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="email_business"
                                label="อีเมล* / Email"
                                :rules="requiredemail_biz"
                                :error-messages="email_businessError"
                                required
                                @input="$v.email_business.$touch()"
                                @blur="$v.email_business.$touch()"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md6 lg6 class="mt-1">
                              <v-layout>
                                <v-flex xs6 sm6 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0 font-weight-medium"
                                    v-model="phone_number_biz"
                                    label="เบอร์มือถือ* / Mobile No."
                                    maxlength="10"
                                    :rules="requiredTextphone"
                                    :error-messages="phone_number_bizError"
                                    required
                                    @input="$v.phone_number_biz.$touch()"
                                    @blur="$v.phone_number_biz.$touch()"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs6 sm6 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0 font-weight-medium"
                                    v-model="tel_biz"
                                    maxlength="10"
                                    label="เบอร์โทรศัพท์* / Telephone"
                                    :rules="requiredTexttelephone"
                                    :error-messages="tel_bizError"
                                    required
                                    @input="$v.tel_biz.$touch()"
                                    @blur="$v.tel_biz.$touch()"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md12 lg12 class="mt-7 text-left"
                              >ข้อมูลที่อยู่ (Address)</v-flex
                            >
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md4 lg4 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-2 pb-0 font-weight-medium"
                                v-model="noaddress"
                                label="เลขที่อยู่* / Number"
                                :rules="requirednoaddress"
                                :error-messages="noaddressError"
                                required
                                @input="$v.noaddress.$touch()"
                                @blur="$v.noaddress.$touch()"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md4 lg4 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-2 pb-0 font-weight-medium"
                                v-model="building"
                                label="อาคาร / Building"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs6 sm6 md4 lg4 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="floor"
                                label="ชั้นที่ / Floor"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs6 sm6 md4 lg4 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="noroom"
                                label="เลขที่ห้อง / Room Number"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md4 lg4 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="mooban"
                                label="หมู่บ้าน / Village"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs4 sm4 md4 lg4 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="no_moo"
                                label="หมู่ที่ / Moo"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs4 sm4 md4 lg4 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="soi"
                                label="ตรอก/ซอย / Soi"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs4 sm4 md4 lg4 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="yaek"
                                label="แยก / Yeak"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs6 sm6 md4 lg4 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="road"
                                label="ถนน / Road"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs6 sm6 md4 lg4 class="mt-1">
                              <v-autocomplete
                                ref="province"
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="province"
                                :items="listprovince"
                                label="จังหวัด* / Province"
                                :rules="[rules.noSpecialChar]"
                                :error-messages="provinceError"
                                required
                                @input="$v.province.$touch()"
                                @blur="$v.province.$touch()"
                              ></v-autocomplete>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs6 sm6 md4 lg4 class="mt-1">
                              <v-autocomplete
                                ref="district"
                                outlined
                                dense
                                clearable
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="district"
                                :items="listdistrict"
                                label="อำเภอ/เขต* / District"
                                :rules="[rules.noSpecialChar]"
                                :error-messages="districtError"
                                required
                                @input="$v.district.$touch()"
                                @blur="$v.district.$touch()"
                              ></v-autocomplete>
                            </v-flex>
                            <v-flex xs6 sm6 md4 lg4 class="mt-1">
                              <v-autocomplete
                                ref="subdistrict"
                                outlined
                                dense
                                clearable
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="subdistrict"
                                :items="listsubdistrict"
                                label="ตำบล/แขวง* / Sub-district"
                                :rules="[rules.noSpecialChar]"
                                :error-messages="subdistrictError"
                                required
                                @input="$v.subdistrict.$touch()"
                                @blur="$v.subdistrict.$touch()"
                              ></v-autocomplete>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs12 sm12 md4 lg4 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="postcode"
                                label="รหัสไปรษณีย์* / Postcode"
                                maxlength="5"
                                :rules="requiredpostcode"
                                :error-messages="postcodeError"
                                required
                                @input="$v.postcode.$touch()"
                                @blur="$v.postcode.$touch()"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm12 md4 lg4 class="mt-1">
                              <v-text-field
                                clearable
                                outlined
                                dense
                                class="pa-1 pt-0 pb-0 font-weight-medium"
                                v-model="fax"
                                label="โทรสาร / Fax"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs5 sm5 md5 lg5 class="mt-1">
                              หนังสือรับรองบริษัท(PDF)*<br />(Company
                              Certificate)
                              <br />
                              <span style="color:red;font-size: 11px;"
                                >*อัปโหลดไฟล์ได้ไม่เกิน 10 MB</span
                              >
                            </v-flex>
                            <v-flex xs7 sm7 md7 lg7 class="mt-1">
                              <v-file-input
                                v-model="companycertificate"
                                label="กรุณาเลือกไฟล์ / Please select a file"
                                outlined
                                dense
                                type="file"
                                accept="application/pdf"
                                required
                                :rules="[rules.noSpecialChar]"
                                :error-messages="companycertificateError"
                                @input="$v.companycertificate.$touch()"
                                @blur="$v.companycertificate.$touch()"
                              ></v-file-input>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <v-flex xs5 md5 lg5 class="mt-1">
                              หนังสือรับมอบอำนาจ(PDF)<br />(Power of attorney)
                              <br />
                              <p>
                                <a @click="examplefile()"
                                  >แบบฟอร์มหนังสือมอบอำนาจและตัวอย่าง (Power of
                                  Attorney Form and Document Example)</a
                                >
                                <br />
                                <span style="color:red;font-size: 11px;"
                                  >*อัปโหลดไฟล์ได้ไม่เกิน 10 MB</span
                                >
                              </p>
                            </v-flex>
                            <v-flex xs7 sm7 md7 lg7 class="mt-1">
                              <v-file-input
                                v-model="powerofattorney"
                                label="กรุณาเลือกไฟล์ / Please select a file"
                                outlined
                                dense
                                type="file"
                                accept="application/pdf"
                                @input="$v.powerofattorney.$touch()"
                                @blur="$v.powerofattorney.$touch()"
                              ></v-file-input>
                            </v-flex>
                          </v-layout>
                          <v-layout>
                            <!-- <v-flex xs6 sm6 md12 lg12 class="mt-2">
                              <v-btn large outlined @click="fn_logout()"
                                >ออกจากระบบ<br />(Log out)</v-btn
                              >
                            </v-flex> -->
                            <v-flex xs6 sm6 md12 lg12 class="text-right mt-2">
                              <v-btn
                                large
                                color="primary"
                                @click="fn_uploadapprove_document()"
                                >ลงทะเบียน<br />Register</v-btn
                              >
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-layout>
                <v-layout row wrap align-center justify-center v-if="e1 == 3">
                  <v-flex xs12 lg12>
                    <v-card v-if="resolutionScreen >= 500">
                      <v-card-text>
                        <v-form v-model="valid">
                          <v-container fluid>
                            <v-layout row wrap justify-center>
                              <v-flex
                                xs12
                                sm12
                                md12
                                lg12
                                class="text-center mt-6"
                              >
                                <v-icon size="256px" color="primary"
                                  >mdi-check-circle-outline</v-icon
                                >
                                <br />
                                <br />
                                <br />
                                <h1 style="font-size: 25px;">
                                  ลงทะเบียนบัญชีนิติบุคคลสำเร็จ
                                </h1>
                                <br />
                                <h3 style="color:#00C853;">
                                  (Register Business Successfully)
                                </h3>
                                <br />
                                <p
                                  class="font-weight-regular"
                                  style="font-size: 16px;"
                                >
                                  ระบบใช้เวลาดำเนินการยืนยันเอกสารภายใน 2
                                  วันทำการ
                                  หากดำเนินการเรียบร้อยแล้วจะแจ้งกลับทาง email
                                </p>
                                <p
                                  class="font-weight-regular"
                                  style="font-size: 16px;"
                                >
                                  หากมีข้อสงสัยหรือพบปัญหา สามารถติดต่อได้ที่
                                  <a>onebox@inet.co.th</a>
                                </p>
                                <br />
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-form>
                      </v-card-text>
                    </v-card>
                    <v-card v-else>
                      <v-card-text>
                        <v-form v-model="valid">
                          <v-container fluid>
                            <v-layout row wrap justify-center>
                              <v-flex xs12 sm12 md12 lg12 class="text-center">
                                <v-icon size="256px" color="primary"
                                  >mdi-check-circle-outline</v-icon
                                >
                                <br />
                                <br />
                                <br />
                                <h1 style="font-size: 25px;">
                                  ลงทะเบียนบัญชีนิติบุคคลสำเร็จ
                                </h1>
                                <br />
                                <h3 style="color:#00C853;">
                                  (Register Business Successfully)
                                </h3>
                                <br />
                                <p
                                  class="font-weight-regular"
                                  style="font-size: 16px;"
                                >
                                  ระบบใช้เวลาดำเนินการยืนยันเอกสารภายใน 2
                                  วันทำการ
                                  หากดำเนินการเรียบร้อยแล้วจะแจ้งกลับทาง email
                                </p>
                                <p
                                  class="font-weight-regular"
                                  style="font-size: 16px;"
                                >
                                  หากมีข้อสงสัยหรือพบปัญหา สามารถติดต่อได้ที่
                                  <a>onebox@inet.co.th</a>
                                </p>
                                <br />
                              </v-flex>
                            </v-layout>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                          </v-container>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <dialogoneid
      :show="opendialogoneid"
      @closedialog="opendialogoneid = false"
    ></dialogoneid>
    <dialogpolicyoneid
      :show="opendialogpolicyoneid"
      @closedialog="opendialogpolicyoneid = false"
    ></dialogpolicyoneid>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import VueCookies from "vue-cookies";
import CryptoJS from "crypto-js";
const dialogoneid = () => import("../optional/dialog-dialogoneid.vue");
const dialogpolicyoneid = () =>
  import("../optional/dialog-dialogpolicyoneid.vue");
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import axios from "axios";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  props: ["show"],
  mixins: [validationMixin],
  validations: {
    typename_th: { required },
    firstnameth: { required },
    lastnameth: { required },
    typename_eng: { required },
    firstnameeng: { required },
    lastnameeng: { required },
    typecrad: { required },
    typecrad_num: { required },
    email: { required },
    confirmpassword: { required },
    password: { required },
    phonenumber: { required },
    // date: { required },
    tax_num: { required },
    typebusiness: { required },
    name_business_th: { required },
    typebusiness_eng: { required },
    name_business_eng: { required },
    name_invoice_th: { required },
    name_invoice_eng: { required },
    radios: { required },
    branch_no: { required },
    branch_name: { required },
    email_business: { required },
    phone_number_biz: { required },
    tel_biz: { required },
    noaddress: { required },
    province: { required },
    district: { required },
    subdistrict: { required },
    postcode: { required },
    companycertificate: { required },
    // powerofattorney: { required },
    tax_num_2: { required },
  },
  components: {
    dialogoneid,
    dialogpolicyoneid,
  },
  data: function() {
    return {
      noaddress_2: "",
      building_2: "",
      floor_2: "",
      noroom_2: "",
      mooban_2: "",
      no_moo_2: "",
      soi_2: "",
      yaek_2: "",
      road_2: "",
      subdistrict_2: "",
      district_2: "",
      province_2: "",
      postcode_2: "",
      BranchNumber_2: "",
      business_Surname_2: "",
      email_2: "",
      full_business_name_2: "",
      full_name_th_2: "",
      name_on_document_th_2: "",
      taxid_2: "",
      tel_2: "",
      tax_num_2: "",
      typebusiness_2: "",
      name_business_th_2: "",
      name_invoice_th_2: "",
      typebusiness_eng_2: "",
      name_business_eng_2: "",
      name_invoice_eng_2: "",
      email_business_2: "",
      phone_number_biz_2: "",
      tel_biz_2: "",
      fax_2: "",
      register1: false,
      show_icon: false,
      username_login: "",
      list_amphoe: [],
      list_district: [],
      list_subdistrict: [],
      list_zipcode: [],
      opendialogpreview: false,
      thai_e_mail: "",
      loaddataprogress: false,
      e1: 1,
      tax_num: "",
      typebusiness: "",
      listtypebusiness: [
        "ห้างหุ้นส่วนสามัญ",
        "ห้างหุ้นส่วนจำกัด",
        "บริษัทจำกัด",
        "บริษัทมหาชนจำกัด",
        "นิติบุคคลอื่นๆ ภายใต้กฎหมายเฉพาะ",
      ],
      resultDocument: "",
      approveDocument: "",
      name_business_th: "",
      listtypebusiness_eng: [
        "Ordinary Partnership",
        "Limited partnership",
        "Company limited",
        "Public Limited Company",
        "Other",
      ],
      typebusiness_eng: "",
      name_business_eng: "",
      name_invoice_th: "",
      name_invoice_eng: "",
      radios: "",
      branch_no: "",
      branch_name: "",
      email_business: "",
      phone_number_biz: "",
      tel_biz: "",
      noaddress: "",
      building: "",
      floor: "",
      noroom: "",
      mooban: "",
      no_moo: "",
      soi: "",
      yaek: "",
      road: "",
      province: "",
      district: "",
      subdistrict: "",
      postcode: "",
      fax: "",
      // listprovince: [],
      listdistrict: [],
      listsubdistrict: [],
      companycertificate: [],
      powerofattorney: [],
      allowcheckpassword: "",
      allowcheckuser: "",
      check_accept: false,
      opendialogoneid: false,
      opendialogpolicyoneid: false,
      checkbox: false,
      checkregistersuccess: false,
      createprogress: false,
      valid: "",
      valid_: "",
      typename_th: "",
      firstnameth: "",
      lastnameth: "",
      typename_eng: "",
      firstnameeng: "",
      lastnameeng: "",
      typecrad: "",
      typecrad_num: "",
      email: "",
      confirmpassword: "",
      password: "",
      phonenumber: "",
      showpassword: false,
      listtypenameth: ["นาย", "นาง", "นางสาว", "พระครู"],
      listtypenameeng: ["Mr", "Mrs", "Miss", "PHRAKHU"],
      listtypecrad: [
        {
          listtypecrad_type: "บัตรประชาชน (ID Card)",
          listtypecrad_value: "บัตรประชาชน",
        },
        {
          listtypecrad_type: "หนังสือเดินทาง (Passport)",
          listtypecrad_value: "หนังสือเดินทาง",
        },
      ],
      listprovince: [
        "กระบี่",
        "กรุงเทพมหานคร",
        "กาญจนบุรี",
        "กาฬสินธุ์",
        "กำแพงเพชร",
        "ขอนแก่น",
        "จันทบุรี",
        "ฉะเชิงเทรา",
        "ชลบุรี",
        "ชัยนาท",
        "ชัยภูมิ",
        "ชุมพร",
        "ตรัง",
        "ตราด",
        "ตาก",
        "นครนายก",
        "นครปฐม",
        "นครพนม",
        "นครราชสีมา",
        "นครศรีธรรมราช",
        "นครสวรรค์",
        "นนทบุรี",
        "นราธิวาส",
        "น่าน",
        "บึงกาฬ",
        "บุรีรัมย์",
        "ปทุมธานี",
        "ประจวบคีรีขันธ์",
        "ปราจีนบุรี",
        "ปัตตานี",
        "พระนครศรีอยุธยา",
        "พะเยา",
        "พังงา",
        "พัทลุง",
        "พิจิตร",
        "พิษณุโลก",
        "ภูเก็ต",
        "มหาสารคาม",
        "มุกดาหาร",
        "ยะลา",
        "ยโสธร",
        "ระนอง",
        "ระยอง",
        "ราชบุรี",
        "ร้อยเอ็ด",
        "ลพบุรี",
        "ลำปาง",
        "ลำพูน",
        "ศรีสะเกษ",
        "สกลนคร",
        "สงขลา",
        "สตูล",
        "สมุทรปราการ",
        "สมุทรสงคราม",
        "สมุทรสาคร",
        "สระบุรี",
        "สระแก้ว",
        "สิงห์บุรี",
        "สุพรรณบุรี",
        "สุราษฎร์ธานี",
        "สุรินทร์",
        "สุโขทัย",
        "หนองคาย",
        "หนองบัวลำภู",
        "อำนาจเจริญ",
        "อุดรธานี",
        "อุตรดิตถ์",
        "อุทัยธานี",
        "อุบลราชธานี",
        "อ่างทอง",
        "เชียงราย",
        "เชียงใหม่",
        "เพชรบุรี",
        "เพชรบูรณ์",
        "เลย",
        "แพร่",
        "แม่ฮ่องสอน",
      ],
      rules: {
        noSpecialChar: (value) => !!value || "โปรดระบุข้อมูล",
      },
      required_: [(value) => !!value || "โปรดระบุข้อมูล."],
      requiredpostcode: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          (value && value.length == 5 && this.fn_checkzibcode(value)) ||
          "โปรดระบุรหัสไปรษณีย์ / Please fill Postcode",
      ],
      requirednoaddress: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          (value && this.fn_check_address(value)) ||
          "โปรดระบุเลขที่อยู่ / Please fill Number",
      ],
      requiredbranch_no: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          (value && value.length == 5 && this.fn_checkidcard(value)) ||
          "โปรดระบุรหัสสาขาเลข 5 หลัก / Please fill Branch No.",
      ],
      requiredtax_num: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          (value && value.length == 13 && this.fn_checkidcard(value)) ||
          "โปรดระบุเลขประจำตัวผู้เสียภาษี 13 หลัก / Please fill Identification Number",
      ],
      requiredTextphone: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          (value && value.length == 10 && this.fn_checkphonenumber(value)) ||
          "โปรดระบุตัวเลข 10 หลัก / Please fill 10 digits",
      ],
      requiredTexttelephone: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          (value &&
            value.length >= 9 &&
            value.length < 11 &&
            this.fn_checkphonenumber(value)) ||
          "โปรดระบุตัวเลข 9-10 หลัก / Please fill 9-10 digits",
      ],
      requiredidcard: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          (value && value.length == 13 && this.fn_checkidcard(value)) ||
          "โปรดระบุตัวเลข 13 หลัก / Please fill 13 digits",
      ],
      requiredemail_biz: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          this.fn_checkemail(value) || "โปรดระบุอีเมล / Please fill Email",
      ],
      requiredemail: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          /.+@.+\..+/.test(value) || "ข้อมูลไม่ถูกต้อง / Please fill Email",
      ],
      // && this.fn_checkSpecialChar(value)
      requiredusername: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          (/^(?=.*[a-z])/.test(value) &&
            value.length >= 6 &&
            this.fn_checkusername(value)) ||
          "ตัวอักษรอย่างน้อย 6 ตัว และห้ามมีอักขระพิเศษ / Must have at least 6 characters",
      ],
      requiredpassword: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          (/^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(value) &&
            this.fn_checkSpecialChar_(value)) ||
          "ต้องมีตัวเลข และตัวอักษรภาษาอังกฤษ อย่างน้อย 1 ตัว ความยาวอย่างน้อย 8 / Must have at least 8 characters",
      ],
      requiredconfirmpassword: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          this.checkpass(value) || "รหัสผ่านไม่ตรงกัน / Passwords do not match",
      ],
      requiredname_th: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          this.checkname_th(value) || "ข้อมูลไม่ถูกต้อง / Invalid data",
      ],
      requiredname: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) => this.checkname(value) || "ข้อมูลไม่ถูกต้อง / Invalid data",
      ],
      requirednameeng: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          this.checknameeng(value) || "ข้อมูลไม่ถูกต้อง / Invalid data",
      ],
      requiredname_eng: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          this.checkname_eng(value) || "ข้อมูลไม่ถูกต้อง / Invalid data",
      ],
      // birthdaydate: null,
      date: null,
      menu: false,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    province() {
      if (this.province !== "-") {
        this.fn_selectdetail_amphoe();
      }
    },
    district() {
      if (this.province !== "-") {
        this.fn_selectdetail_district();
        // this.fn_selectdetail_zipcode()
      }
    },
    subdistrict() {
      if (this.province !== "-") {
        this.fn_selectdetail_zipcode();
      }
    },
    name_business_th() {
      this.fn_name_th();
    },
    name_business_eng() {
      this.fn_name_eng();
    },
    typebusiness() {
      this.fn_checktypebizth();
      this.fn_name_th();
    },
    typebusiness_eng() {
      this.fn_checktypebizeng();
      this.fn_name_eng();
    },
    typebusiness_2() {
      this.fn_checktypebizth_2();
      this.fn_name_th_2();
    },
    typebusiness_eng_2() {
      this.fn_checktypebizeng_2();
      this.fn_name_eng_2();
    },
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    shower: {
      get() {
        if (this.show === true) {
        }
        return this.show;
      },
    },
    // powerofattorneyError() {
    //   const errors = [];
    //   if (!this.$v.powerofattorney.$dirty) return errors;
    //   !this.$v.powerofattorney.required &&
    //     errors.push("กรุณาอัปโหลดเอกสาร / Please Upload Documents");
    //   return errors;
    // },
    companycertificateError() {
      const errors = [];
      if (!this.$v.companycertificate.$dirty) return errors;
      !this.$v.companycertificate.required &&
        errors.push("กรุณาอัปโหลดเอกสาร / Please Upload Documents");
      return errors;
    },
    subdistrictError() {
      const errors = [];
      if (!this.$v.subdistrict.$dirty) return errors;
      !this.$v.subdistrict.required &&
        errors.push("โปรดระบุตำบล/แขวง / Please fill Sub-District");
      return errors;
    },
    districtError() {
      const errors = [];
      if (!this.$v.district.$dirty) return errors;
      !this.$v.district.required &&
        errors.push("โปรดระบุอำเภอ/เขต / Please fill District");
      return errors;
    },
    provinceError() {
      const errors = [];
      if (!this.$v.province.$dirty) return errors;
      !this.$v.province.required &&
        errors.push("โปรดระบุจังหวัด / Please fill Province");
      return errors;
    },
    postcodeError() {
      const errors = [];
      if (!this.$v.postcode.$dirty) return errors;
      !this.$v.postcode.required &&
        errors.push("โปรดระบุรหัสไปรษณีย์ / Please fill Postcode");
      return errors;
    },
    noaddressError() {
      const errors = [];
      if (!this.$v.noaddress.$dirty) return errors;
      !this.$v.noaddress.required &&
        errors.push("โปรดระบุเลขที่อยู่ / Please fill Number");
      return errors;
    },
    email_businessError() {
      const errors = [];
      if (!this.$v.email_business.$dirty) return errors;
      !this.$v.email_business.required &&
        errors.push("โปรดระบุอีเมล / Please fill Email");
      return errors;
    },
    emailError() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("โปรดระบุอีเมล");
      return errors;
    },
    branch_nameError() {
      const errors = [];
      if (!this.$v.branch_name.$dirty) return errors;
      !this.$v.branch_name.required &&
        errors.push("โปรดระบุชื่อสาขา / Please fill Branch Name");
      return errors;
    },
    branch_noError() {
      const errors = [];
      if (!this.$v.branch_no.$dirty) return errors;
      !this.$v.branch_no.required &&
        errors.push("โปรดระบุรหัสสาขา / Please fill Branch No");
      return errors;
    },
    name_invoice_engError() {
      const errors = [];
      if (!this.$v.name_invoice_eng.$dirty) return errors;
      !this.$v.name_invoice_eng.required &&
        errors.push(
          "โปรดระบุชื่อที่ใช้พิมพ์ในใบกำกับภาษี(อังกฤษ) / Please fill Tax invoice name (Eng)"
        );
      return errors;
    },
    name_invoice_thError() {
      const errors = [];
      if (!this.$v.name_invoice_th.$dirty) return errors;
      !this.$v.name_invoice_th.required &&
        errors.push(
          "โปรดระบุชื่อที่ใช้พิมพ์ในใบกำกับภาษี(ไทย) / Please fill Tax invoice name (TH)"
        );
      return errors;
    },
    name_business_engError() {
      const errors = [];
      if (!this.$v.name_business_eng.$dirty) return errors;
      !this.$v.name_business_eng.required &&
        errors.push(
          "โปรดระบุชื่อผู้ประกอบการ / Please fill Business Name (Eng)"
        );
      return errors;
    },
    typebusiness_engError() {
      const errors = [];
      if (!this.$v.typebusiness_eng.$dirty) return errors;
      !this.$v.typebusiness_eng.required &&
        errors.push(
          "โปรดระบุประเภทนิติบุคคล / Please fill Type Business (Eng)"
        );
      return errors;
    },
    name_business_thError() {
      const errors = [];
      if (!this.$v.name_business_th.$dirty) return errors;
      !this.$v.name_business_th.required &&
        errors.push(
          "โปรดระบุชื่อผู้ประกอบการ / Please fill Business Name (TH)"
        );
      return errors;
    },
    tax_numError() {
      const errors = [];
      if (!this.$v.tax_num.$dirty) return errors;
      !this.$v.tax_num.required &&
        errors.push(
          "โปรดระบุเลขประจำตัวผู้เสียภาษี / Please fill Identification Number"
        );
      return errors;
    },
    typebusinessError() {
      const errors = [];
      if (!this.$v.typebusiness.$dirty) return errors;
      !this.$v.typebusiness.required &&
        errors.push("โปรดระบุประเภทนิติบุคคล / Please fill Type Business (TH)");
      return errors;
    },
    typename_thError() {
      const errors = [];
      if (!this.$v.typename_th.$dirty) return errors;
      !this.$v.typename_th.required &&
        errors.push(
          "โปรดระบุคำนำหน้าชื่อ(ภาษาไทย) / Please fill Title Name (TH)"
        );
      return errors;
    },
    firstnamethError() {
      const errors = [];
      if (!this.$v.firstnameth.$dirty) return errors;
      !this.$v.firstnameth.required &&
        errors.push("โปรดระบุชื่อ(ภาษาไทย) / Please fill First Name (TH)");
      return errors;
    },
    lastnamethError() {
      const errors = [];
      if (!this.$v.lastnameth.$dirty) return errors;
      !this.$v.lastnameth.required &&
        errors.push("โปรดระบุนามสกุล(ภาษาไทย) / Please fill Last Name (TH)");
      return errors;
    },
    typename_engError() {
      const errors = [];
      if (!this.$v.typename_eng.$dirty) return errors;
      !this.$v.typename_eng.required &&
        errors.push(
          "โปรดระบุคำนำหน้าชื่อ(ภาษาอังกฤษ) / Please fill Title Name (Eng)"
        );
      return errors;
    },
    firstnameengError() {
      const errors = [];
      if (!this.$v.firstnameeng.$dirty) return errors;
      !this.$v.firstnameeng.required &&
        errors.push("โปรดระบุชื่อ(ภาษาอังกฤษ) / Please fill First Name (Eng)");
      return errors;
    },
    lastnameengError() {
      const errors = [];
      if (!this.$v.lastnameeng.$dirty) return errors;
      !this.$v.lastnameeng.required &&
        errors.push("โปรดระบุนามสกุล(ภาษอังกฤษ) / Please fill Last Name (Eng)");
      return errors;
    },
    typecradError() {
      const errors = [];
      if (!this.$v.typecrad.$dirty) return errors;
      !this.$v.typecrad.required &&
        errors.push("โปรดระบุชนิดบัตร / Please fill Type Card");
      return errors;
    },
    typecrad_numError() {
      const errors = [];
      if (!this.$v.typecrad_num.$dirty) return errors;
      !this.$v.typecrad_num.required &&
        errors.push("โปรดระบุข้อมูล / Please fill Type Card Number");
      return errors;
    },
    emailError() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required &&
        errors.push("โปรดระบุอีเมล / Please fill Email");
      return errors;
    },
    phone_number_bizError() {
      const errors = [];
      if (!this.$v.phone_number_biz.$dirty) return errors;
      !this.$v.phone_number_biz.required &&
        errors.push("โปรดระบุเบอร์โทร / Please fill Mobile No.");
      return errors;
    },
    tel_bizError() {
      const errors = [];
      if (!this.$v.tel_biz.$dirty) return errors;
      !this.$v.tel_biz.required &&
        errors.push("โปรดระบุเบอร์โทร / Please fill Telephone");
      return errors;
    },
    phonenumberError() {
      const errors = [];
      if (!this.$v.phonenumber.$dirty) return errors;
      !this.$v.phonenumber.required &&
        errors.push("โปรดระบุเบอร์โทร / Please fill Mobile No.");
      return errors;
    },
    dateError() {
      const errors = [];
      if (!this.$v.date.$dirty) return errors;
      !this.$v.date.required &&
        errors.push("โปรดระบุข้อมูลวันเกิด / Please fill Date of Birth");
      return errors;
    },
    usernameError() {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;
      !this.$v.username.required &&
        errors.push("โปรดระบุข้อมูลชื่อผู้ใช้ / Please fill Username");
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required &&
        errors.push(
          "โปรดระบุรหัสผ่าน / Please fill Password / Must have at least 8 characters "
        );
      return errors;
    },
    confirmpasswordError() {
      const errors = [];
      if (!this.$v.confirmpassword.$dirty) return errors;
      !this.$v.confirmpassword.required &&
        errors.push(
          "โปรดยืนยันรหัสผ่าน / Please fill Password / Must have at least 8 characters "
        );
      return errors;
    },
  },
  methods: {
    fn_selectdetail_amphoe() {
      // this.district = "";
      // this.subdistrict = "";
      // this.postcode = "";
      this.list_amphoe = [];
      let listamphoe = [];
      let listsubdistrict = [];
      let payload = {
        // district: "",
        // amphoe: "",
        // zipcode: "",
        // district_code: "",
        // amphoe_code: "",
        // province_code: "",
        province: this.province,
      };
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN + "/api/get_Tambon", payload)
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("dataresponse", response);

            for (let i = 0; i < response.data.result.length; i++) {
              listamphoe.push(response.data.result[i].amphoe);
            }
            console.log("listamphoe", listamphoe);
            const arr = Array.from(new Set(listamphoe));
            console.log("arr", arr);
            this.listdistrict = arr;
            // this.list_amphoe = Array.from(new Set(listamphoe))
            // this.listdistrict =  this.list_amphoe;
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },
    fn_selectdetail_district() {
      // this.subdistrict = "";
      // this.postcode = "";
      this.list_subdistrict = [];
      let listsubdistrict = [];
      let payload = {
        // district: "",
        amphoe: this.district,
        // zipcode: "",
        // district_code: "",
        // amphoe_code: "",
        // province_code: "",
        province: this.province,
      };
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN + "/api/get_Tambon", payload)
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("dataresponse", response);
            for (let i = 0; i < response.data.result.length; i++) {
              listsubdistrict.push(response.data.result[i].district);
            }
            const arr = Array.from(new Set(listsubdistrict));
            this.listsubdistrict = arr;
            // this.list_subdistrict = Array.from(new Set(listsubdistrict))
            // this.listsubdistrict =  this.list_subdistrict;
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },
    fn_selectdetail_zipcode() {
      // this.postcode = "";
      let listzip;
      let payload = {
        district: this.subdistrict,
        amphoe: this.district,
        zipcode: "",
        district_code: "",
        amphoe_code: "",
        province_code: "",
        province: this.province,
      };
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN + "/api/get_Tambon", payload)
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("dataresponse", response);
            const zip = response.data.result.find(
              (v) =>
                v.province === this.province &&
                v.district === this.subdistrict &&
                v.amphoe === this.district
            );
            console.log("in", zip);
            listzip = zip.zipcode;
            this.postcode = listzip.toString();
            // listzip = response.data.result[0].zipcode
            // this.postcode = listzip.toString();
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },
    fn_search_taxid() {
      if (this.tax_num.length === 13) {
        // this.loaddataprogress = true;
        let i;
        let payload = {
          taxid: this.tax_num,
        };
        console.log("payload", payload);
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_ADMIN + "/api/get_data_business",
            payload
          )
          .then((response) => {
            if (response.data.status === "OK") {
              console.log("dataresponse", response);

              this.noaddress = response.data.result[0].address.HouseNumber;
              this.building = response.data.result[0].address.BuildingName;
              this.floor = response.data.result[0].address.FloorNumber;
              this.noroom = response.data.result[0].address.RoomNumber;
              this.mooban = "-";
              this.no_moo = response.data.result[0].address.MooNumber;
              this.soi = response.data.result[0].address.SoiName;
              this.yaek = "-";
              this.road = response.data.result[0].address.StreetName;
              this.subdistrict = response.data.result[0].address.Thambol;
              this.district = response.data.result[0].address.Amphur;
              this.province = response.data.result[0].address.Province;
              this.postcode = response.data.result[0].address.PostCode;
              console.log("อำเภอ", this.district);
              console.log("ตำบล", this.subdistrict);

              this.typebusiness =
                response.data.result[0].business.business_TitleName_th;
              this.name_business_th =
                response.data.result[0].business.business_name;
              this.typebusiness_eng =
                response.data.result[0].business.business_TitleName_en;
              // this.name_business_eng = "";
              // this.name_invoice_th = response.data.result[0].business.name_on_document_th;
              // this.name_invoice_eng = "";
              this.radios =
                response.data.result[0].business.BranchNumber === "00000"
                  ? "สำนักงานใหญ่"
                  : "สาขาอื่นๆ";
              this.branch_no = response.data.result[0].business.BranchNumber;
              this.branch_name =
                response.data.result[0].business.BranchNumber === "00000"
                  ? "สำนักงานใหญ่"
                  : "สาขาอื่นๆ";
              this.tel_biz = "";
              // this.fn_selectdetail_amphoe();
              this.loaddataprogress = false;
            } else {
              this.loaddataprogress = false;
              Toast.fire({
                icon: "error",
                title: "ไม่พบข้อมูลบัญชีนิติบุคคล",
                // title: response.data.errorMessage,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
            });
          });
      }
    },
    fn_search_taxid_2() {
      if (this.tax_num_2.length === 13) {
        let i;
        let payload = {
          taxid: this.tax_num_2,
        };
        console.log("payload", payload);
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_ADMIN + "/api/get_data_business",
            payload
          )
          .then((response) => {
            if (response.data.status === "OK") {
              console.log("dataresponse", response);
              this.noaddress_2 = response.data.result[0].address.HouseNumber;
              this.building_2 = response.data.result[0].address.BuildingName;
              this.floor_2 = response.data.result[0].address.FloorNumber;
              this.noroom_2 = response.data.result[0].address.RoomNumber;
              this.mooban_2 = "-";
              this.no_moo_2 = response.data.result[0].address.MooNumber;
              this.soi_2 = response.data.result[0].address.SoiName;
              this.yaek_2 = "-";
              this.road_2 = response.data.result[0].address.StreetName;
              this.subdistrict_2 = response.data.result[0].address.Thambol;
              this.district_2 = response.data.result[0].address.Amphur;
              this.province_2 = response.data.result[0].address.Province;
              this.postcode_2 = response.data.result[0].address.PostCode;

              this.typebusiness_2 =
                response.data.result[0].business.business_TitleName_th;
              this.name_business_th_2 =
                response.data.result[0].business.business_name;
              this.typebusiness_eng_2 =
                response.data.result[0].business.business_TitleName_en;

              this.BranchNumber_2 =
                response.data.result[0].business.BranchNumber;
              this.business_Surname_2 =
                response.data.result[0].business.business_Surname;
              this.email_2 = response.data.result[0].business.email;
              this.full_business_name_2 =
                response.data.result[0].business.full_business_name;
              this.full_name_th_2 =
                response.data.result[0].business.full_name_th;
              this.name_on_document_th_2 =
                response.data.result[0].business.name_on_document_th;
              this.taxid_2 = response.data.result[0].business.taxid;
              this.tel_2 = response.data.result[0].business.tel;

              this.loaddataprogress = false;
            } else {
              this.loaddataprogress = false;
              Toast.fire({
                icon: "error",
                // title: response.data.errorMessage,
                title: "ไม่พบข้อมูลบัญชีนิติบุคคล",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
            });
          });
      }
    },
    fn_logout() {
      this.$router.push("logout");
    },

    //check radio สาขา
    fn_radiobranch() {
      if (this.radios === "สำนักงานใหญ่") {
        this.branch_no = "00000";
        this.branch_name = "สำนักงานใหญ่";
      } else {
        this.branch_no = "0000";
        this.branch_name = "";
      }
    },
    //รหัสไปรษณีย์
    fn_checkpostcode(data) {
      var thai = require("thai-data");
      var provinceName = thai.provinceName(data);
      var districtName = thai.districtName(data);
      var subDistrictName = thai.subDistrictName(data);
      this.listprovince = [provinceName];
      this.listdistrict = districtName;
      this.listsubdistrict = subDistrictName;
    },
    fn_opendialog1() {},
    save(date) {
      this.$refs.menu.save(date);
    },
    checkpass(value) {
      if (value === this.password) {
        return true;
      } else {
        return false;
      }
    },
    fn_checkSpecialChar(value) {
      let allow = true;
      let specialChar = [
        " ",
        "!",
        "#",
        "^",
        "&",
        "(",
        ")",
        "+",
        "$",
        "%",
        "<",
        ">",
        ":",
        "*",
        "?",
        "\\",
        "|",
        "/",
        '"',
        "'",
      ];

      for (let index = 0; index < specialChar.length; index++) {
        const element = specialChar[index];
        if (value.includes(element)) {
          allow = false;
          break;
        }
      }
      // console.log("allow", allow);
      return allow;
    },
    fn_checkemail(value) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    fn_checkSpecialChar_(value) {
      let allow = true;
      let specialChar = [" ", '"', "'"];

      for (let index = 0; index < specialChar.length; index++) {
        const element = specialChar[index];
        if (value.includes(element)) {
          allow = false;
          break;
        }
      }
      this.allowcheckpassword = allow;
      console.log("allow", allow);
      return allow;
    },
    fn_check_address(value) {
      const pattern = /^[0-9-.,()/]{1,}$/i;
      // const pattern_ = /^[0-9]{1,}$/i;
      //  || pattern_.test(value) == true
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    fn_checkzibcode(value) {
      const pattern = /^[0-9]{1,}$/i;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    fn_checkidcard(value) {
      const pattern = /^[0-9]{1,}$/i;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    fn_checkphonenumber(value) {
      const pattern = /^0[0-9]{8,9}$/i;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    checkname_eng(value) {
      const pattern = /^[a-zA-Z\s]+$/;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    checkname_th(value) {
      // const pattern = /^[ก-๏\s]+\S+$/;
      // const pattern_ = /^[ก-๏\s]+$/;
      const pattern = /^[ก-๏\s0-9๑-๙,.()/-]+$/;

      // if (pattern.test(value) == true || pattern_.test(value) == true) {
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    checkname(value) {
      const pattern = /^[ก-๏\W0-9]+$/;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    fn_checkusername(value) {
      console.log("value fn_checkusername", value);
      // const pattern = /^[a-z0-9]+$/;
      const pattern = /^[a-z0-9]*$/;
      //const pattern = /^(?=.*[a-z])(?=.*[0-9]){8,}/;
      if (pattern.test(value) == true) {
        this.allowcheckuser = true;
        return true;
      } else {
        this.allowcheckuser = false;
        return false;
      }
    },
    checknameeng(value) {
      const pattern = /^[a-zA-Z&\s0-9()-.,/]+$/;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    //citizen
    fn_register() {
      this.$v.$touch();
      // let allowcheckusername = this.fn_checkusername();
      // if (!this.$v.$invalid) {
      if (this.password !== this.confirmpassword) {
        Toast.fire({
          icon: "error",
          title: "รหัสผ่านไม่ตรงกัน กรุณายืนยันรหัสผ่านอีกครั้ง",
        });
        this.password = "";
        this.confirmpassword = "";
      } else if (this.allowcheckuser == false) {
        Toast.fire({
          icon: "error",
          title: "รูปแบบชื่อผู้ใช้งานไม่ถูกต้อง",
        });
        // this.username = '';
      } else if (this.allowcheckpassword == false) {
        Toast.fire({
          icon: "error",
          title: "รูปแบบรหัสผ่านไม่ถูกต้อง",
        });
      } else {
        console.log("fn_checkusername", this.fn_checkusername);
        this.createprogress = true;
        let payload = {
          account_title_th: this.typename_th,
          first_name_th: this.firstnameth,
          last_name_th: this.lastnameth,
          account_title_eng: this.typename_eng,
          first_name_eng: this.firstnameeng,
          last_name_eng: this.lastnameeng,
          id_card_type:
            this.typecrad === "บัตรประชาชน"
              ? "idcard"
              : this.typecrad === "หนังสือเดินทาง"
              ? "passport"
              : "",
          id_card_num: this.typecrad_num,
          email: this.email,
          mobile_no: this.phonenumber,
          birth_date: this.date,
          username: this.username,
          password: this.password,
        };
        console.log("payload", payload);
        // let auth = await gbfGenerate.generateToken();
        // console.log("auth",auth);
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_REGISTER + "/api/register_account",
            payload,
            {
              headers: {
                Authorization:
                  `Bearer ${process.env.VUE_APP_BEARER_TOKEN}`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            if (response.data.status === "OK") {
              this.createprogress = false;
              console.log("response ok", response);
              Toast.fire({
                icon: "success",
                title: this.$t("register.regissuccess"),
              });
              this.checkregistersuccess = true;
              // localStorage.setItem("stepregister", 2);
              // this.fn_cleardata();
              // this.$router.push("/login");
            } else {
              this.createprogress = false;
              // console.log("response notok", response);
              // console.log("response notok", response.data.errorMessage);
              Toast.fire({
                icon: "error",
                title:
                  response.data.errorMessage.id_card_num ||
                  response.data.errorMessage.first_name_th ||
                  response.data.errorMessage.last_name_th ||
                  response.data.errorMessage.first_name_eng ||
                  response.data.errorMessage.last_name_eng ||
                  response.data.errorMessage.email ||
                  response.data.errorMessage.mobile_no ||
                  response.data.errorMessage.birth_date ||
                  response.data.errorMessage.username ||
                  response.data.errorMessage.password ||
                  response.data.errorMessage.id_card_type ||
                  response.data.errorMessage,
              });
              if (response.data.errorMessage.id_card_num) {
                this.typecrad_num = "";
              } else if (response.data.errorMessage.first_name_th) {
                this.firstnameth = "";
              } else if (response.data.errorMessage.last_name_th) {
                this.lastnameth = "";
              } else if (response.data.errorMessage.first_name_eng) {
                this.firstnameeng = "";
              } else if (response.data.errorMessage.last_name_eng) {
                this.lastnameeng = "";
              }
              if (response.data.errorMessage.email) {
                this.email = "";
              } else if (response.data.errorMessage.mobile_no) {
                this.phonenumber = "";
              } else if (response.data.errorMessage.birth_date) {
                this.date = "";
              } else if (response.data.errorMessage.username) {
                this.username = "";
              } else if (response.data.errorMessage.password) {
                this.password = "";
              }
            }
          })
          .catch((error) => {
            this.createprogress = false;
            Toast.fire({
              icon: "error",
              title: error,
            });
            console.log(error);
          });
      }
      // }
    },
    //อัปโหลดหนังสือรับรองบริษัท
    async fn_uploadapprove_document() {
      //   this.step_two();
      //   this.$v.$touch();
      // if (!this.$v.$invalid) {
      this.loaddataprogress = true;
      // let decode = localStorage.getItem("token");
      // let decode = VueCookies.get("token");
      let decode;
        if(process.env.NODE_ENV === "production"){
          decode = sessionStorage.getItem("token");
        }else{
          // decode = VueCookies.get("token");
          decode = sessionStorage.getItem("token");
        }
      let token = window.atob(decode);
      //   let token = VueCookies.get("token");
      console.log("token", token);
      let tokenOneid = "Bearer" + " " + token;
      if (this.companycertificate !== "" || this.companycertificate !== null) {
        const formData = new FormData();
        formData.append("approveDocument", this.companycertificate);
        console.log("formdata", formData);

        //  https://one.th/api/upload_approve_api
        await this.axios
          .post(
            process.env.VUE_APP_ONEID + "/api/upload_approve_api",
            formData,
            {
              headers: { Authorization: tokenOneid },
            }
          )
          .then((response) => {
            console.log("upload res", response);
            if (response.data.code === 200) {
              if (response.data.result === "Success") {
                this.approveDocument = response.data.data;
                // console.log("response",response);
                console.log("this.approveDocument", this.approveDocument);
                this.fn_result_meeting();
              } else {
                this.loaddataprogress = false;
                Toast.fire({
                  icon: "error",
                  title: response.data.errorMessage.approveDocument[0],
                });
              }
            } else {
              this.loaddataprogress = false;
              Toast.fire({
                icon: "error",
                title:
                  response.data.errorCode + " : " + response.data.errorMessage,
              });
            }
          })
          .catch((error) => {
            this.loaddataprogress = false;
            console.log("error upload", error);
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถอัปโหลดไฟล์ขนาดเกิน 10 MB",
            });
          });
      } else {
        this.loaddataprogress = false;
        Toast.fire({
          icon: "error",
          title: "กรุณาเลือกไฟล์ที่ต้องการอัปโหลด",
        });
      }
      // }
    },
    //หนังสือมอบอำนาจ
    async fn_result_meeting() {
      // let decode = localStorage.getItem("token");
      // let decode = VueCookies.get("token");
      let decode;
        if(process.env.NODE_ENV === "production"){
          decode = sessionStorage.getItem("token");
        }else{
          // decode = VueCookies.get("token");
          decode = sessionStorage.getItem("token");
        }
      let token = window.atob(decode);
      //   let token = VueCookies.get("token");
      let tokenOneid = "Bearer" + " " + token;
      // if (this.powerofattorney !== "" || this.powerofattorney !== null) {
      console.log("this.powerofattorney",this.powerofattorney);
      const formData = new FormData();
      if (this.powerofattorney === "" || this.powerofattorney === null || this.powerofattorney.length === 0) {
        formData.append("resultDocument", this.companycertificate);
      } else {
        formData.append("resultDocument", this.powerofattorney);
      }
      console.log("formdata", formData);
      // https://one.th/api/upload_result_api
      await this.axios
        .post(process.env.VUE_APP_ONEID + "/api/upload_result_api", formData, {
          headers: { Authorization: tokenOneid },
        })
        .then((response) => {
          console.log("upload res", response);
          if (response.data.code === 200) {
            if (response.data.result === "Success") {
              this.resultDocument = response.data.data;
              this.fn_regisbusiness();
            } else {
              this.loaddataprogress = false;
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage.resultDocument[0],
              });
            }
          } else {
            this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              title:
                response.data.errorCode + " : " + response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          this.loaddataprogress = false;
          console.log("error upload", error);
        });
      // } else {
      //   this.loaddataprogress = false;
      //   Toast.fire({
      //     icon: "error",
      //     title: "กรุณาเลือกไฟล์ที่ต้องการอัปโหลด",
      //   });
      // }
    },
    //business
    async fn_regisbusiness() {
      console.log(this.dataAccountActive);
      console.log("citi", this.dataCitizenProfile);
      // let decode = localStorage.getItem("token");
      // let decode = VueCookies.get("token");
      let decode;
        if(process.env.NODE_ENV === "production"){
          decode = sessionStorage.getItem("token");
        }else{
          // decode = VueCookies.get("token");
          decode = sessionStorage.getItem("token");
        }
      let token = window.atob(decode);
      //   let token = VueCookies.get("token");
      let tokenOneid = "Bearer" + " " + token;
      this.$v.$touch();
      // this.step_two();
      // if (!this.$v.$invalid) {
      // this.createprogress = true;
      let payload = {
        account_title_eng: this.typebusiness_eng,
        account_title_th: this.typebusiness,
        address: [
          {
            house_code: "",
            house_no: this.noaddress,
            room_no: this.noroom,
            moo_ban: this.mooban,
            moo_no: this.no_moo,
            building_name: this.building,
            floor: this.floor,
            yaek: this.yaek,
            street: this.road,
            fax_number: this.fax,
            soi: this.soi,
            province: this.province,
            tambon: this.subdistrict,
            amphoe: this.district,
            zipcode: this.postcode,
            country: "Thailand",
          },
        ],
        branch_name: this.branch_name,
        branch_no: this.branch_no,
        email: this.email_business,
        first_name_eng: this.name_business_eng,
        first_name_th: this.name_business_th,
        id_card_type: "TAX_ID",
        id_card_num: this.tax_num,
        mobile_no: this.phone_number_biz,
        name_on_document_th: this.name_invoice_th,
        name_on_document_eng: this.name_invoice_eng,
        owner_detail: [
          {
            thai_email: this.dataCitizenProfile.thai_email,
            department: "owner",
            position: "owner",
          },
          {
            thai_email: this.dataCitizenProfile.thai_email,
            department: "RA",
            position: "RA",
          },
        ],
        tel_no: this.tel_biz,
        approve_document: this.approveDocument,
        result_meeting: this.resultDocument,
      };
      //
      console.log("payload", payload);
      await this.axios
        .post(process.env.VUE_APP_ONEID + "/api/register_business", payload, {
          headers: { Authorization: tokenOneid },
        })
        .then((response) => {
          console.log(response);
          if (response.data.code === 200) {
            if (response.data.result === "Success") {
              this.createprogress = false;
              console.log("response ok", response);
              Toast.fire({
                icon: "success",
                title: this.$t("register.regissuccess"),
              });
              this.checkregistersuccess = true;
              this.fn_sendemail();
              this.e1 = 3;
              // this.$router.replace("/login-register");
            } else {
              this.loaddataprogress = false;
              console.log(response.data.errorMessage);
              Toast.fire({
                icon: "error",
                title:
                  response.data.errorMessage === "id duplicate"
                    ? "Tax ID Duplicate"
                    : response.data.errorMessage,
              });
            }
          } else {
            this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          this.loaddataprogress = false;
          this.createprogress = false;
          Toast.fire({
            icon: "error",
            title:
              error.response.data.errorMessage ===
              "This branch_no dupicate [pending]."
                ? "Tax ID Duplicate"
                : error.response.data.errorMessage,
          });
          console.log("Error400", error.response.data);
        });
      // }
    },
    fn_register_2() {
      // let decode = localStorage.getItem("token");
      let decode;
        if(process.env.NODE_ENV === "production"){
          decode = sessionStorage.getItem("token");
        }else{
          decode = VueCookies.get("token");
        }
      let token_ = window.atob(decode);
      // let token_ = localStorage.getItem("token");
      let payload = {
        accesstoken: token_,
        thai_email: this.email_2,
        account_title_th: this.typebusiness_2,
        first_name_th: this.name_business_th_2,
        account_title_eng: this.typebusiness_eng_2,
        first_name_eng: this.name_business_eng_2,
        full_name_th: this.name_invoice_th_2,
        full_name_eng: this.name_invoice_eng_2,
        email: this.email_business_2,
        tel_no: this.tel_biz_2 === "" ? "0000000000" : this.tel_biz_2,
        mobile_no: this.phone_number_biz_2,
        taxid: this.tax_num_2,
        branch_name: "-",
        branch_no: this.BranchNumber_2,
        house_code: "-",
        house_no: this.noaddress_2,
        room_no: this.noroom_2,
        moo_ban: this.mooban_2,
        moo_no: this.no_moo_2,
        building_name: this.building_2,
        floor: this.floor_2,
        yaek: this.yaek_2,
        street: this.road_2,
        fax_number: this.fax_2,
        soi: this.soi_2,
        province: this.province_2,
        tambon: this.subdistrict_2,
        amphoe: this.district_2,
        zipcode: this.postcode_2,
        country: "Thailand",
      };
      console.log("=payload=", payload);
      this.loaddataprogress = true;
      this.createprogress = true;
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_API +
            "/api/register_biz_by_onebox",
          payload
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            this.createprogress = false;
            console.log("response ok", response);
            Toast.fire({
              icon: "success",
              title: this.$t("register.regissuccess"),
            });
            this.checkregistersuccess = true;
            this.fn_sendemail_2();
            this.e1 = 3;
          } else {
            this.loaddataprogress = false;
            this.createprogress = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          this.loaddataprogress = false;
          this.createprogress = false;
          Toast.fire({
            icon: "error",
            title: error,
          });
          console.log(error);
        });
    },
    fn_sendemail() {
      let payload = {
        taxid: this.tax_num,
        business_name_th: this.name_invoice_th,
        branch_no: this.branch_no,
        email: this.email_business,
        tel_no: this.phone_number_biz,
        username: this.dataCitizenProfile.username,
        branch_name: this.branch_name,
      };
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN + "/api/noti_register_business",
          payload
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            // this.createprogress = false;
            console.log("response ok", response);
            // Toast.fire({
            //   icon: "success",
            //   title: this.$t("register.regissuccess"),
            // });
          } else {
            this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          this.loaddataprogress = false;
          this.createprogress = false;
          Toast.fire({
            icon: "error",
            title: error,
          });
          console.log(error);
        });
    },
    fn_sendemail_2() {
      let payload = {
        taxid: this.tax_num_2,
        business_name_th: this.name_invoice_th_2,
        branch_no: "00000",
        email: this.email_business_2,
        tel_no: this.phone_number_biz_2,
        username: this.dataCitizenProfile.username,
        branch_name: "-",
      };
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN + "/api/noti_register_business",
          payload
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            // this.createprogress = false;
            console.log("response ok", response);
            this.loaddataprogress = false;
            // Toast.fire({
            //   icon: "success",
            //   title: this.$t("register.regissuccess"),
            // });
          } else {
            this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          this.loaddataprogress = false;
          this.createprogress = false;
          Toast.fire({
            icon: "error",
            title: error,
          });
          console.log(error);
        });
    },
    clearbtnnameth() {
      if (this.name_business_th === "" || this.name_business_th === null) {
        this.name_invoice_th = "";
      }
    },
    clearbtnnameeng() {
      if (this.name_business_eng === "" || this.name_business_eng === null) {
        this.name_invoice_eng = "";
      }
    },
    fn_name_th() {
      if (this.name_business_th !== "" || this.name_business_th !== null) {
        if (this.typebusiness === "ห้างหุ้นส่วนสามัญ") {
          this.name_invoice_th =
            "ห้างหุ้นส่วนสามัญ" + " " + this.name_business_th;
        } else if (this.typebusiness === "ห้างหุ้นส่วนจำกัด") {
          this.name_invoice_th =
            "ห้างหุ้น" + " " + this.name_business_th + " " + "จำกัด";
        } else if (this.typebusiness === "บริษัทจำกัด") {
          this.name_invoice_th =
            "บริษัท" + " " + this.name_business_th + " " + "จำกัด";
        } else if (this.typebusiness === "บริษัทมหาชนจำกัด") {
          this.name_invoice_th =
            "บริษัท" + " " + this.name_business_th + " " + "จำกัด (มหาชน)";
        } else if (this.typebusiness === "นิติบุคคลอื่นๆ ภายใต้กฎหมายเฉพาะ") {
          this.name_invoice_th = this.name_business_th;
        }
      } else {
        this.name_invoice_th = "";
      }
    },
    fn_name_eng() {
      if (this.name_business_eng !== "" || this.name_business_eng !== null) {
        if (this.typebusiness_eng === "Ordinary Partnership") {
          this.name_invoice_eng =
            this.name_business_eng + " " + "Ordinary Partnership";
        } else if (this.typebusiness_eng === "Limited partnership") {
          this.name_invoice_eng =
            this.name_business_eng + " " + "Limited partnership";
        } else if (this.typebusiness_eng === "Company limited") {
          this.name_invoice_eng =
            this.name_business_eng + " " + "Company limited";
        } else if (this.typebusiness_eng === "Public Limited Company") {
          this.name_invoice_eng =
            this.name_business_eng + " " + "Public Limited Company";
        } else if (this.typebusiness_eng === "Other") {
          this.name_invoice_eng = this.name_business_eng;
        }
      } else {
        this.name_invoice_eng = "";
      }
    },
    fn_name_th_2() {
      if (this.name_business_th_2 !== "" || this.name_business_th_2 !== null) {
        if (this.typebusiness_2 === "ห้างหุ้นส่วนสามัญ") {
          this.name_invoice_th_2 =
            "ห้างหุ้นส่วนสามัญ" + " " + this.name_business_th_2;
        } else if (this.typebusiness_2 === "ห้างหุ้นส่วนจำกัด") {
          this.name_invoice_th_2 =
            "ห้างหุ้น" + " " + this.name_business_th_2 + " " + "จำกัด";
        } else if (this.typebusiness_2 === "บริษัทจำกัด") {
          this.name_invoice_th_2 =
            "บริษัท" + " " + this.name_business_th_2 + " " + "จำกัด";
        } else if (this.typebusiness_2 === "บริษัทมหาชนจำกัด") {
          this.name_invoice_th_2 =
            "บริษัท" + " " + this.name_business_th_2 + " " + "จำกัด (มหาชน)";
        } else if (this.typebusiness_2 === "นิติบุคคลอื่นๆ ภายใต้กฎหมายเฉพาะ") {
          this.name_invoice_th_2 = this.name_business_th;
        }
      } else {
        this.name_invoice_th_2 = "";
      }
    },
    fn_name_eng_2() {
      if (
        this.name_business_eng_2 !== "" ||
        this.name_business_eng_2 !== null
      ) {
        if (this.typebusiness_eng_2 === "Ordinary Partnership") {
          this.name_invoice_eng_2 =
            this.name_business_eng_2 + " " + "Ordinary Partnership";
        } else if (this.typebusiness_eng_2 === "Limited partnership") {
          this.name_invoice_eng_2 =
            this.name_business_eng_2 + " " + "Limited partnership";
        } else if (this.typebusiness_eng_2 === "Company limited") {
          this.name_invoice_eng_2 =
            this.name_business_eng_2 + " " + "Company limited";
        } else if (this.typebusiness_eng_2 === "Public Limited Company") {
          this.name_invoice_eng_2 =
            this.name_business_eng_2 + " " + "Public Limited Company";
        } else if (this.typebusiness_eng_2 === "Other") {
          this.name_invoice_eng_2 = this.name_business_eng_2;
        }
      } else {
        this.name_invoice_eng_2 = "";
      }
    },
    fn_cleardata() {
      this.typename_th = "";
      this.firstnameth = "";
      this.lastnameth = "";
      this.typename_eng = "";
      this.firstnameeng = "";
      this.lastnameeng = "";
      this.typecrad = "";
      this.typecrad_num = "";
      this.email = "";
      this.phonenumber = "";
      this.date = "";
      this.username = "";
      this.password = "";
    },
    fn_checktypenameth() {
      if (this.typename_th === "นาย") {
        this.typename_eng = "Mr";
      } else if (this.typename_th === "นาง") {
        this.typename_eng = "Mrs";
      } else if (this.typename_th === "นางสาว") {
        this.typename_eng = "Miss";
      }
    },
    fn_checktypenameeng() {
      if (this.typename_eng === "Mr") {
        this.typename_th = "นาย";
      } else if (this.typename_eng === "Mrs") {
        this.typename_th = "นาง";
      } else if (this.typename_eng === "Miss") {
        this.typename_th = "นางสาว";
      }
    },
    fn_checktypebizth() {
      if (this.typebusiness === "ห้างหุ้นส่วนสามัญ") {
        this.typebusiness_eng = "Ordinary Partnership";
      } else if (this.typebusiness === "ห้างหุ้นส่วนจำกัด") {
        this.typebusiness_eng = "Limited partnership";
      } else if (this.typebusiness === "บริษัทจำกัด") {
        this.typebusiness_eng = "Company limited";
      } else if (this.typebusiness === "บริษัทมหาชนจำกัด") {
        this.typebusiness_eng = "Public Limited Company";
      } else if (this.typebusiness === "นิติบุคคลอื่นๆ ภายใต้กฎหมายเฉพาะ") {
        this.typebusiness_eng = "Other";
      }
    },
    fn_checktypebizeng() {
      if (this.typebusiness_eng === "Ordinary Partnership") {
        this.typebusiness = "ห้างหุ้นส่วนสามัญ";
      } else if (this.typebusiness_eng === "Limited partnership") {
        this.typebusiness = "ห้างหุ้นส่วนจำกัด";
      } else if (this.typebusiness_eng === "Company limited") {
        this.typebusiness = "บริษัทจำกัด";
      } else if (this.typebusiness_eng === "Public Limited Company") {
        this.typebusiness = "บริษัทมหาชนจำกัด";
      } else if (this.typebusiness_eng === "Other") {
        this.typebusiness = "นิติบุคคลอื่นๆ ภายใต้กฎหมายเฉพาะ";
      }
    },
    fn_checktypebizth_2() {
      if (this.typebusiness_2 === "ห้างหุ้นส่วนสามัญ") {
        this.typebusiness_eng_2 = "Ordinary Partnership";
      } else if (this.typebusiness_2 === "ห้างหุ้นส่วนจำกัด") {
        this.typebusiness_eng_2 = "Limited partnership";
      } else if (this.typebusiness_2 === "บริษัทจำกัด") {
        this.typebusiness_eng_2 = "Company limited";
      } else if (this.typebusiness_2 === "บริษัทมหาชนจำกัด") {
        this.typebusiness_eng_2 = "Public Limited Company";
      } else if (this.typebusiness_2 === "นิติบุคคลอื่นๆ ภายใต้กฎหมายเฉพาะ") {
        this.typebusiness_eng_2 = "Other";
      }
    },
    fn_checktypebizeng_2() {
      if (this.typebusiness_eng_2 === "Ordinary Partnership") {
        this.typebusiness_2 = "ห้างหุ้นส่วนสามัญ";
      } else if (this.typebusiness_eng_2 === "Limited partnership") {
        this.typebusiness_2 = "ห้างหุ้นส่วนจำกัด";
      } else if (this.typebusiness_eng_2 === "Company limited") {
        this.typebusiness_2 = "บริษัทจำกัด";
      } else if (this.typebusiness_eng_2 === "Public Limited Company") {
        this.typebusiness_2 = "บริษัทมหาชนจำกัด";
      } else if (this.typebusiness_eng_2 === "Other") {
        this.typebusiness_2 = "นิติบุคคลอื่นๆ ภายใต้กฎหมายเฉพาะ";
      }
    },
    open() {
      this.opendialogoneid = true;
    },
    fn_tolowercase() {
      this.username = this.username.toLowerCase();
    },
    examplefile() {
      window.open("https://uatbox.one.th/admin/api/one_id_form");
    },
    // step_one() {
    //   this.tax_num = "-";
    //   this.typebusiness = "-";
    //   this.name_business_th = "-";
    //   this.typebusiness_eng = "-";
    //   this.name_business_eng = "-";
    //   this.name_invoice_th = "-";
    //   this.name_invoice_eng = "-";
    //   this.radios = "-";
    //   this.branch_no = "-";
    //   this.branch_name = "-";
    //   this.email_business = "-";
    //   this.phone_number_biz = "-";
    //   this.tel_biz = "-";
    //   this.noaddress = "-";
    //   this.province = "-";
    //   this.district = "-";
    //   this.subdistrict = "-";
    //   this.postcode = "-";
    //   this.companycertificate = "-";
    //   this.powerofattorney = "-";
    // },
    step_two() {
      this.typename_th = "-";
      this.firstnameth = "-";
      this.lastnameth = "-";
      this.typename_eng = "-";
      this.firstnameeng = "-";
      this.lastnameeng = "-";
      this.typecrad = "-";
      this.typecrad_num = "-";
      this.email = "-";
      this.username = "-";
      this.confirmpassword = "-";
      this.password = "-";
      this.phonenumber = "-";
    },
    // fn_checkstep() {
    //   console.log(localStorage.getItem("stepregister"));
    //   let stepregister = localStorage.getItem("stepregister");
    //   if (stepregister == 1) {
    //     this.e1 = 1;
    //   } else {
    //     this.check_accesstoken_oneid();
    //   }
    //   // this.e1 = localStorage.getItem("stepregister");
    // },
    // check_accesstoken_oneid() {
    //   // this.loaddataprogress = true;
    //   // authorize/api/check_accesstoken_oneid
    //   // let auth = await gbfGenerate.generateToken();
    //   let token = VueCookies.get("token");
    //   this.axios
    //     .get(
    //       process.env.VUE_APP_SERVICE_AUTHORIZE_API +
    //         "/api/check_accesstoken_oneid",
    //       {
    //         headers: { accesstoken: token },
    //       }
    //     )
    //     .then((response) => {
    //       console.log("respone_checkaccesstoken", response);
    //       if (response.data.status === "OK") {
    //         // this.loaddataprogress = false;
    //         // this.fn_checkstep();
    //         this.e1 = 2;
    //         // this.thai_e_mail = localStorage.getItem("thai_email");
    //         // this.username_login = localStorage.getItem("username");
    //         //  Toast.fire({
    //         //   icon: "success",
    //         //   title: "อัปเดตสำเร็จ"
    //         // });
    //       } else {
    //         // this.loaddataprogress = false;
    //         Toast.fire({
    //           icon: "error",
    //           title: response.data.errorMessage,
    //         });
    //         this.$router.replace({ path: "/" });
    //         // this.$router.replace({ path: "/login-register" });
    //       }
    //     })
    //     .catch((error) => {
    //       Toast.fire({
    //         icon: "error",
    //         title: error,
    //       });
    //       console.log(error);
    //     });
    // },
  },
  mounted() {
    // this.check_accesstoken_oneid();
    // localStorage.getItem("stepregister")

    // this.fn_checkstep();
    // this.thai_e_mail = localStorage.getItem("thai_email");
    this.thai_e_mail = CryptoJS.AES.decrypt(
      VueCookies.get("thai_email"),
      // localStorage.getItem("thai_email"),
      CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),
      { mode: CryptoJS.mode.ECB }
    );
    this.thai_e_mail = window.atob(
      this.thai_e_mail.toString(CryptoJS.enc.Utf8)
    );
    this.username_login = this.dataCitizenProfile.username;

    console.log("this.thai_e_mail", this.thai_e_mail);
    console.log("this.username_login", this.username_login);
    console.log("--", this.dataCitizenProfile);
  },
};
</script>
